<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="getFilteredPersone()">
        <div class="row">
          <div class="col filter">
            <input
              type="text"
              class="form-control"
              placeholder="Codice Fiscale"
              aria-label=""
              :value="cod_fisc"
              @input="updateCod"
            />
          </div>
          <div class="col filter">
            <input
              type="text"
              class="form-control"
              placeholder="Cognome"
              aria-label=""
              :value="cognome"
              @input="updateCognome"
            />
          </div>
          <div class="col filter">
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              aria-label=""
              :value="nome"
              @input="updateNome"
            />
          </div>

          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="E-mail"
              aria-label=""
              :value="mail"
              @input="updateMail"
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Matricola"
              aria-label=""
              :value="matricola"
              @input="updateMatricola"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            v-if="!isActive"
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="getFilteredPersone()"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            v-if="!isActive"
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="resetFilters"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// N.B: pagina con metodi obsoleti ma funzionanti
import { defineComponent, ref, computed } from "vue";

import { useStore } from "vuex";

import "vue3-date-time-picker/dist/main.css";

export default defineComponent({
  name: "FiltriPersone",
  props: {},
  emits: ["getPersone", "resetFilters"],

  setup(props, { emit }) {
    //inizializzazioni
    const store = useStore();

    let isActive = ref(false);

    // chiamate - store ACTIONS
    function getFilteredPersone() {
      store.commit("setRowsToSkipPersone");
      emit("getPersone");
    }

    // FUNZIONI esterne - store MUTATIONS
    const updateNome = (e) => {
      store.commit("setNome", e.target.value);
    };
    const updateCognome = (e) => {
      store.commit("setCognome", e.target.value);
    };
    const updateCod = (e) => {
      store.commit("setCod", e.target.value);
    };
    const updateMatricola = (e) => {
      store.commit("setMatricola", e.target.value);
    };
    const updateMail = (e) => {
      store.commit("setMail", e.target.value);
    };
    const updateTipoTessera = (e) => {
      if (e.target.value === "-") {
        store.commit("setTipoTessera", null);
        return;
      }
      store.commit("setTipoTessera", e.target.value);
    };
    const updateTipoAlbo = (e) => {
      if (e.target.value === "-") {
        store.commit("setTipoAlbo", null);
        return;
      }
      store.commit("setTipoAlbo", e.target.value);
    };
    const setAeg = (value) => {
      store.commit("setAeg", value);
    };
    function resetFilters() {
      emit("resetFilters");
    }

    // page bindings
    return {
      // campi e variabili pagina
      isActive,
      // funzionalità
      getFilteredPersone,
      resetFilters,
      updateNome,
      updateCognome,
      updateCod,
      updateMatricola,
      updateMail,
      updateTipoTessera,
      updateTipoAlbo,
      setAeg,
      // liste old style
      nome: computed(() => store.getters.nome),
      cognome: computed(() => store.getters.cognome),
      cod_fisc: computed(() => store.getters.cod_fisc),
      matricola: computed(() => store.getters.matricola),
      mail: computed(() => store.getters.mail),
      tipoTessera: computed(() => store.getters.tipoTessera),
      tipiTessera: computed(() => store.getters.tipiTessereLookup),
      tipoAlbo: computed(() => store.getters.tipoAlbo),
      tipiAlbo: computed(() => store.getters.tipiAlboLookup),
      loading: computed(() => store.getters.loadingPersone),

      loaded: computed(() =>
        store.getters.getStateFromName("loadedpersone_list")
      ),
    };
  },
  components: {},
});
</script>
