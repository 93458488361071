<template>
  <div>
    <div class="row mt-5">
      <div class="col-4">
        <div v-if="!readOnly">
          <router-link
            to="/persone/add-persona"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi anagrafica
            persona
          </router-link>
        </div>
      </div>
      <div class="col-4 text-center">
        <div>
          <button
            v-if="isEnabled('fnPersoneExportTessereGold')"
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_gold"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta
            tesserati Gold
          </button>
        </div>
      </div>
      <ModalEsportaGold />
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b>
            {{ rowToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowToSkip + fetchRows > record ? record : rowToSkip + fetchRows
            }}
          </b>
          Persone di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div v-if="status === 200 || !status">
      <div>
        <!-- :pushToObject="{
              name: `anagrafica-persona`,
              params: { fromPersone: true },
            }"
            :querystringPushLink="`?fromPersone=true`" -->
        <div v-if="loaded && record != 0">
          <Datatable
            :table-header="tableHeader"
            :table-data="persone_list"
            :total="record"
            :current-page="currentPage"
            :rows-per-page="fetchRows"
            :sortLabel="sortColumn"
            :order="sortOrder"
            @sort="onSort"
            @current-change="onPageChange"
          >
            <template v-slot:cell-cod_fisc="{ row: data }">
              <div class="tab-long">
                <span :class="{ 'text-danger': !data.cf_ok }">
                  <div class="d-flex">
                    {{ data.cod_fisc }}
                    <Popper
                      v-if="!data.cf_ok"
                      arrow
                      :content="data.ck_no_ok_avviso"
                      style="margin: -8px"
                    >
                      <span :class="{ 'text-danger': !data.cf_ok }">
                        <i
                          class="bi bi-exclamation-triangle-fill text-danger"
                        ></i>
                      </span>
                    </Popper>
                  </div>
                </span>
              </div>
              <span :class="{ 'text-danger': !data.cf_ok }">
                <i
                  v-if="!data.cf_ok"
                  class="bi bi-exclamation-triangle-fill text-danger"
                ></i>
                {{ data.cod_fisc }}
              </span>
            </template>
            <template v-slot:cell-cognome="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                {{ data.cognome }}
              </router-link>
            </template>
            <template v-slot:cell-nome="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                {{ data.nome }}</router-link
              >
            </template>
            <template v-slot:cell-data_nascita="{ row: data }"
              ><div class="text-center">
                <router-link
                  as="div"
                  :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                  class="d-flex"
                >
                  {{ data.data_nascita }}
                </router-link>
              </div>
            </template>
            <template v-slot:cell-localita_nascita="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                {{ data.localita_nascita }}
              </router-link>
            </template>
            <template v-slot:cell-matricola="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                {{ data.matricola }}
              </router-link>
            </template>
            <template v-slot:cell-cittadinanza="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                {{ data.cittadinanza }}
              </router-link>
            </template>
            <template v-slot:cell-options="{ row: data }">
              <router-link
                as="div"
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id}`"
                class="d-flex"
              >
                <i class="bi bi-eye text-dark fs-5"></i>
              </router-link>
            </template>
          </Datatable>
        </div>
        <div class="mx-auto my-auto text-center" v-else-if="!loaded">
          <div class="spinner-border text-gray-600" role="status"></div>
          <span class="text-gray-600 ps-3 fs-2">Loading</span>
        </div>
        <div
          v-else-if="record === 0"
          class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
        >
          <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Nessun risultato.
          </span>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="text-gray-600 pt-4"
            @click.prevent="resetFilters"
          >
            <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
          ></span>
        </div>
      </div>
      <br />
    </div>
    <div
      v-else
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="resetFilters"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import RouterLink from "vue-router";
import ModalEsportaGold from "./ModalEsportaGold.vue";
import isEnabled from "@/composables/isEnabled.js";
import Popper from "vue3-popper";
export default {
  name: "PersoneTable",
  components: {
    Datatable,
    RouterLink,
    ModalEsportaGold,
    Popper,
  },
  emits: ["getPersone", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    // inizializzazioni
    const tableHeader = ref([
      {
        name: "Codice Fiscale",
        key: "cod_fisc",
      },
      {
        name: "Cognome",
        key: "cognome",
      },
      {
        name: "Nome",
        key: "nome",
      },
      {
        name: "Data di nascita",
        key: "data_nascita",
        align: "center",
      },
      {
        name: "Luogo di nascita",
        key: "localita_nascita",
      },
      {
        name: "Matricola",
        key: "matricola",
      },
      {
        name: "Cittadinanza",
        key: "cittadinanza",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    // chiamate - store ACTIONS
    store.dispatch("lookupsPersoneListFromApi");

    // funzionalità - store ACTIONS / MUTATION
    function onPageChange(page) {
      store.commit("setPagePersone", page);
      emit("getPersone");
    }

    function onChangeFetchRow(num) {
      store.commit("setRowsToSkipPersone");
      store.commit("setFetchRowsPersone", num);
      emit("getPersone");
    }

    function resetFilters() {
      emit("resetFilters");
    }
    const onSort = ({ columnName, order }) => {
      store.commit("setSortColumnPersone", columnName);
      store.commit("setSortOrderPersone", order);
      emit("getPersone");
    };

    const setTutteLeStagioni = () => {
      store.commit("setTutteLeStagioniPersone");
      emit("getPersone");
    };

    return {
      // campi e variabili pagina
      tableHeader,
      isEnabled,
      // liste old style
      persone: computed(() => store.getters.persone),
      currentPage: computed(() => store.getters.currentPagePersone),
      error: computed(() => store.getters.errorPersone),
      loading: computed(() => store.getters.loadingPersone),
      totalElements: computed(() => store.getters.totalPersone),
      fetchRows: computed(() => store.getters.fetchRowsPersone),
      rowToSkip: computed(() => store.getters.rowToSkipPersone),
      sortColumn: computed(() => store.getters.sortColumnPersone),
      sortOrder: computed(() => store.getters.sortOrderPersone),
      // funzionalità
      resetFilters,
      onPageChange,
      onSort,
      onChangeFetchRow,
      setTutteLeStagioni,
      tutteStagioniPersone: computed(() => store.getters.tutteStagioniPersone),

      persone_list: computed(() =>
        store.getters.getStateFromName("resultspersone_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedpersone_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordpersone_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuspersone_list")
      ),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>
<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
