<template>
  <div>
    <FiltriPersone @getPersone="searchPersone" @resetFilters="resetFilters" />
    <TablePerson @getPersone="searchPersone" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TablePerson from "@/components/components-fit/persone/TablePersone.vue";
import FiltriPersone from "@/components/components-fit/persone/FiltriPersone.vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";

export default defineComponent({
  name: "PersoneList",
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Persone", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const nome = computed(() => store.getters.nome);
    const cognome = computed(() => store.getters.cognome);
    const cod_fisc = computed(() => store.getters.cod_fisc);
    const fetchRowsPersone = computed(() => store.getters.fetchRowsPersone);
    const rowToSkipPersone = computed(() => store.getters.rowToSkipPersone);
    const sortColumnPersone = computed(() => store.getters.sortColumnPersone);
    const sortOrderPersone = computed(() => store.getters.sortOrderPersone);
    const matricola = computed(() => store.getters.matricola);
    const mail = computed(() => store.getters.mail);
    const aeg = computed(() => store.getters.aeg);
    const stagione = computed(() => store.getters.defaultStagione);
    const tessera = computed(() => store.getters.tessera);
    const tipoAlbo = computed(() => store.getters.tipoAlbo);
    const tipoTessera = computed(() => store.getters.tipoTessera);
    const localita_nascita = computed(() => store.getters.localita_nascita);
    const data_nascita = computed(() => store.getters.data_nascita);

    const getPersone = () => {
      const localKeys = {
        nome: trimInput(nome.value),
        cognome: trimInput(cognome.value),
        cod_fisc: trimInput(cod_fisc.value),
        rowsToSkip: rowToSkipPersone.value,
        fetchRows: fetchRowsPersone.value,
        sortColumn: sortColumnPersone.value,
        sortOrder: sortOrderPersone.value,
        matricola: matricola.value,
        mail: trimInput(mail.value),
        cf_age_stato: aeg.value,
        stagione: stagione.value,
        tessera: tessera.value,
        tipoAlbo: tipoAlbo.value,
        tipoTessera: tipoTessera.value,
        localita_nascita: localita_nascita.value,
        data_nascita: data_nascita.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.PERSONE_LIST,
        itemName: "persone_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersPersone");
      getPersone();
    };
    getPersone();
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedpersone_list")
    );
    const searchPersone = () => {
      if (!loaded.value) return;
      getPersone();
    };

    return {
      getPersone,
      resetFilters,
      searchPersone,
    };
  },
  components: {
    TablePerson,
    FiltriPersone,
  },
});
</script>
